#submit {
    background-color: lightgreen;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(126, 202, 88, 0.76);
}

#submit:hover {
    background-color: rgba(51, 255, 54, 0.76);
}


.paymentConfirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.paymentConfirmMessage{
    text-align: center;
    padding: 10px;
    width: 370px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.paymentSuccessTableDiv {
    padding: 10px 20px;
}

.paymentSuccessTable{
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    padding: 25px;
}

.paymentSuccessTableLeft{
    text-align: left;
    font-weight: 300;
}

.paymentSuccessTableRight{
    text-align: right;
    font-weight: 500;
}




