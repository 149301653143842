.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.header-title{
  margin-left: 50px;
  margin-right: 50px;
}

.site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout-sub-header-background {
	background: #141414;
}
.menu-style {
	height: 100vh;
	width: 200px;
	/* position: fixed; */
}

.row-pointer{
  cursor: pointer;
}